<template>
  <v-flex v-if="_.size(product) > 0" xs12 lg3 sm4 md4>
    <v-card class="mt-0 mx-auto">
      <template v-if="photoDefault !== undefined">
        <img :src="photoDefault.url" style="max-width: 70%; margin: 0 auto; display: block" />
      </template>
      <v-card-title>
        <div class="headline font-weight-regular" v-html="product.name"></div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          v-if="product.status.hidden"
          small
          :disabled="product.status.hidden"
          color="success"
          depressed
          block
          style="flex:1"
        >
          Deshabilitado
        </v-btn>
        <v-btn v-else-if="product.active" color="success" depressed block @click="updateStatus(false)"> Activo </v-btn>
        <v-btn v-else depressed color="error" @click="updateStatus(true)"> Inactivo </v-btn>
        <v-spacer></v-spacer>
        <div class="text-right" style="justify-content: flex-end;display: flex;flex-wrap: wrap;">
          <v-btn depressed small color="error" :href="product.url_site" target="_blank">
            Web <v-icon right> launch </v-icon>
          </v-btn>
          <br />
          <v-btn
            depressed
            color="info"
            :href="`https://www.rosalinda.cl/solo-retiro-en-tienda/santiago-centro?id=${product.id}`"
            target="_blank"
            small
            class="ml-1"
          >
            RETIRO <v-icon right> launch </v-icon>
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider></v-divider>
      <v-list class="ma-0 py-0" :dense="$mq !== 'lg'">
        <template v-for="(item, i) in items">
          <v-layout v-if="item.heading" :key="i" row align-center>
            <v-flex xs12>
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-flex>
          </v-layout>
          <v-divider v-else-if="item.divider" :key="i" dark class="my-3"></v-divider>
          <v-list-tile
            v-else-if="checkRoute(item)"
            :key="i"
            :class="$route.name === item.route ? 'is-active' : ''"
            @click="goRoute(item)"
          >
            <v-list-tile-action class="mr-0 pr-0">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>
                {{ item.text }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider v-if="checkRoute(item)" :key="`divider-${i}`"></v-divider>
        </template>
      </v-list>
    </v-card>
  </v-flex>
</template>

<script>
import { GET_PRODUCT } from '../../config'

export default {
  name: 'BuyerNav',
  props: ['goRoute', 'product', 'getProduct'],
  data() {
    return {
      items: [
        { icon: 'assessment', text: 'Estados', route: 'productStatus' },
        {
          icon: 'fa-hand-holding-usd',
          text: 'Precio',
          route: 'productPrice'
        },
        {
          icon: 'domain',
          text: 'Productos relacionados',
          route: 'productCategory'
        },
        { icon: 'texture', text: 'Venta por seleccion de color', route: 'productColors' },
        { icon: 'texture', text: 'Atributos', route: 'productAttributes' },
        { icon: 'photo', text: 'Imágenes', route: 'productImages' },
        {
          icon: 'featured_play_list',
          text: 'Características',
          route: 'productFeatures'
        },
        {
          icon: 'assignment',
          text: 'Ficha del producto (Seo)',
          route: 'productSeo'
        },
        { icon: 'dns', text: 'Composición', route: 'productReceta' },
        {
          icon: 'assignment',
          text: 'Fotos de entregas',
          route: 'productsDelivery'
        },
        { icon: 'graphic_eq', text: 'Estadisticas', route: 'productStatistics' },
        { icon: 'graphic_eq', text: 'Merchant Center', route: 'productMerchant' }
      ],
      carruselOption: {
        slidesToShow: 1,
        infinite: true,
        centerMode: false,
        dots: false,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 2000,
        swipeToSlide: true,
        arrows: false
      }
    }
  },
  computed: {
    photoDefault() {
      const photo = this._.filter(this.product.images, ['default', true])
      return photo[0]
    },
    comments() {
      return this.$store.getters.getComments
    },
    states() {
      return this.$store.getters.getStates
    },
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {},
  methods: {
    async updateStatusAction(status) {
      try {
        await this.$http.put(`${GET_PRODUCT}/${this.product.id}/status-store`, {
          status
        })
        this.$store.dispatch('setConfirm', { active: false })
        this.getProduct()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    checkRoute(item) {
      const { user } = this
      if (item.route === 'productReceta' && !user.shop.admin) {
        return false
      }
      if (item.route === 'productFeatures' && !user.shop.admin) {
        return false
      }
      if (item.route === 'productsDelivery' && !user.shop.admin) {
        return false
      }
      if (item.route === 'productSeo' && !user.shop.admin) {
        return false
      }
      if (item.route === 'productStatistics' && user.role.id !== 1) {
        return false
      }
      if (item.route === 'productCategory' && !user.shop.admin) {
        return false
      }
      if (item.route === 'productMerchant' && user.role.id !== 1) {
        return false
      }
      return true
    },
    openImage(img) {
      console.log(img)
    },
    updateStatus(status) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: 'Cambiar estado del producto',
        description: '',
        action: () => this.updateStatusAction(status)
      })
    }
  }
}
</script>

<style>
strong {
  font-weight: 600;
}
.footer-sheet {
  position: absolute !important;
  bottom: 0;
  width: 100%;
}
</style>

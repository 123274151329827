<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout v-if="product" wrap align-top justify-left>
        <ProductNav :get-product="getProduct" :product="product" :go-route="goRoute"></ProductNav>
        <v-flex xs12 lg9 sm8 md8>
          <router-view :product="product" :get-product="getProduct" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import ProductNav from './nav.vue'
import { GET_PRODUCT } from '../../config'

export default {
  name: 'Product',
  components: { ProductNav },
  props: ['id'],
  metaInfo: {
    title: 'Productos'
  },
  data: () => ({
    product: {}
  }),
  mounted() {
    this.getProduct()
  },
  methods: {
    goRoute(item) {
      this.$router.push({ name: item.route })
    },
    async getProduct() {
      try {
        const res = await this.$http.get(`${GET_PRODUCT}/${this.id}`)
        this.product = res.data
        this.$store.dispatch('updateTitle', `P${this.product.id} - ${this.product.name}`)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    }
  }
}
</script>
